<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Cài đặt thông tin bảng xếp hạng"
          header-tag="header"
      >
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                  id="campaign_title"
                  label="Tên BXH(*):"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.title"
                    placeholder="Nhập tên BXH..."
                    :class="[{ 'is-invalid': form.errors.has('title') }]"
                ></b-form-input>
                <has-error :form="form" field="title"></has-error>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <b-form-group
                id="input-group-4"
                label="Thời gian bắt đầu(*):"
                label-for="dp1"
                class="col-md-6"
            >
              <date-picker
                  id="dp1"
                  name="dp1"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  v-model="form.date_start"
                  type="datetime"
                  placeholder="Chọn thời gian"
                  :input-attr="{required: 'true',name:''}"
              ></date-picker>
              <has-error :form="form" field="date_start"></has-error>
            </b-form-group>
            <b-form-group
                id="input-group-4"
                label="Thời gian kết thúc(*):"
                label-for="dp1"
                class="col-md-6"
            >
              <date-picker
                  id="dp1"
                  name="dp1"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-type="format"
                  v-model="form.date_end"
                  type="datetime"
                  placeholder="Chọn thời gian"
                  :input-attr="{required: 'true',name:''}"
                  :class="[{ 'is-invalid': form.errors.has('date_end') }]"
              ></date-picker>
              <has-error :form="form" field="date_end"></has-error>
            </b-form-group>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="Ảnh:" >
                <div>
                  <div style="height: 50px;" v-if="form.banner">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.banner"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event, 'banner')"
                      placeholder="chọn ảnh"
                      accept="image/*"
                      type="file"
                      :class="[{ 'is-invalid': form.errors.has('banner') }]"
                  ></b-form-file>
                  <has-error :form="form" field="banner"></has-error>
                </div>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                  id="campaign_title"
                  label="Số lượng users hiển thị(*):"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.limit"
                    placeholder="Nhập số lượng..."
                    :class="[{ 'is-invalid': form.errors.has('limit') }]"
                ></b-form-input>
                <has-error :form="form" field="limit"></has-error>
              </b-form-group>
            </div>

          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-1">
              Kích hoạt
            </div>
            <div class="col-md-3">

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  value="1"
                  unchecked-value="0"
                  size="lg"
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-button variant="warning" style="margin-right: 10px;" @click="onSubmit">Cập nhật</b-button>
              <router-link
                  :to="'/setting/rank-config'"
              >
                <b-button variant="outline-primary">Hủy</b-button>
              </router-link>

            </div>
          </div>

        </b-form>
      </b-card>
    </b-card-group>
  </div>

</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Form } from 'vform';
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import {
  HasError,
} from 'vform/src/components/bootstrap5'
import Vue from "vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component(HasError.name, HasError);


import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("Campaign");

Vue.component("multiselect", Multiselect);

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {DatePicker},
  data() {
    return {
      form: new Form({
        id: '',
        title: '',
        date_start: '',
        date_end: '',
        status: 1,
        limit: '',
        banner: '',
      }),
      show: true,
      is_edit: false,
      clients: [],

    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cài đặt"},
      { title: "Bảng xếp hạng" },
    ]);
  },
  created() {
    this.getRankConfig();
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
      //this.createImage(files[0]);
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              this.form.banner = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async getRankConfig() {
      let response = await WalletCampaignRepository.getRankConfig();
      if (response.data.data.error_code == 0) {
        let data = JSON.parse(response.data.data.data.value);
        this.form.fill(data);
      }
    },
    onSubmit () {
      //this.form.axios = ApiService;
      this.form.submit('post', '/campaign/setting/rank-config')
          .then(({data}) => {
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              //this.$router.push({ name: "setting_rank_config" });
            }
          })
          .catch((error) => {
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    },

  },

};
</script>

<style lang="css" scoped>
.mx-datepicker {
  width: 100% !important;
}
</style>